<template>
  <div v-if="!loading">
    <div class="ps-breadcrumb">
      <div class="ps-container">
        <ul class="breadcrumb">
          <li><a href="">Home</a></li>
          <li>
            <a href="">{{ product.category.name }}</a>
          </li>
          <li>
            <a href="">{{ product.category.name }}</a>
          </li>
          <li>{{ product.name }}</li>
        </ul>
      </div>
    </div>
    <div class="ps-page--product">
      <div class="ps-container">
        <div class="ps-page__container">
          <div class="ps-page__left">
            <div class="ps-product--detail ps-product--fullwidth">
              <div class="ps-product__header">
                <div class="ps-product__thumbnail" data-vertical="true">
                  <figure>
                    <div class="ps-wrapper">
                      <div
                        class="ps-product__gallery"
                        data-arrow="true"
                        style="
                          max-width: 50%;
                          margin-top: -40px;
                          margin-left: 100px;
                        "
                      >
                        <div
                          class="item"
                          v-for="image in product.images"
                          :key="image.id"
                        >
                          <img :src="`${image.path}`" alt="Product" />
                        </div>
                      </div>
                    </div>
                  </figure>
                  <div
                    class="ps-product__variants"
                    data-item="4"
                    data-md="4"
                    data-sm="4"
                    data-arrow="false"
                  >
                    <div
                      class="item"
                      v-for="image in product.images"
                      :key="image.id"
                    >
                      <img
                        style="width: 20vw"
                        :src="`${image.path}`"
                        alt="Product"
                      />
                    </div>
                  </div>
                </div>
                <div class="ps-product__info">
                  <h1>{{ product.name }}</h1>
                  <div class="ps-product__meta">
                    <p class="p-3">
                      Category: &nbsp;
                      <a href="#">{{ product.category.name }}</a>
                    </p>
                  </div>
                  <h4 class="ps-product__price">${{ product.price }}</h4>
                  <div class="ps-product__desc">
                    <p>
                      Sold By:<a :href="'/shop/' + product.shop.slug"
                        ><strong> {{ product.shop.name }}</strong></a
                      >
                    </p>
                    <ul class="ps-list--dot">
                      <li>{{ product.description }}</li>
                    </ul>
                  </div>
                  <div class="ps-product__variations">
                    <figure v-if="productColors">
                      <figcaption>
                        Color: <strong> Choose an option</strong>
                      </figcaption>
                      <div
                        v-for="colorProperty in productColors"
                        :key="colorProperty.id"
                        class="ps-variant ps-variant--size"
                        :style="{
                          'background-color': `${colorProperty.color}`,
                        }"
                      >
                        <span class="ps-variant__tooltip">{{
                          colorProperty.color
                        }}</span>
                        <span class="ps-variant__size"></span>
                      </div>
                    </figure>
                    <figure v-if="productSizes">
                      <figcaption>
                        Size <strong> Choose an option</strong>
                      </figcaption>
                      <div
                        v-for="productSize in productSizes"
                        :key="productSize.id"
                        class="ps-variant ps-variant--size"
                      >
                        <span class="ps-variant__tooltip">{{
                          productSize.size
                        }}</span>
                        <span class="ps-variant__size">{{
                          productSize.size
                        }}</span>
                      </div>
                    </figure>
                  </div>
                  <div class="ps-product__shopping">
                    <figure>
                      <figcaption>Quantity</figcaption>
                      <div class="form-group--number">
                        <button class="up" v-on:click="addQuantity">
                          <i class="icon-plus"></i>
                        </button>
                        <button
                          class="down"
                          :disabled="quantity === 1"
                          v-on:click="removeQuantity"
                        >
                          <i class="icon-minus"></i>
                        </button>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="1"
                          :value="quantity"
                        />
                      </div>
                    </figure>
                  </div>
                  <div class="justify-content-between ps-product__shopping">
                    <div
                      class="row"
                      style="margin-left: 0px; margin-right: 0px; width: 100%"
                    >
                      <div v-if="onCart == false" class="col-12">
                        <a
                          class="ps-btn ps-btn--black"
                          style="margin: 5px 0px"
                          @click="addToCart()"
                          href="#"
                          >Add to cart
                          <div
                            v-if="loading"
                            class="spinner-border text-dark"
                            role="status"
                          ></div>
                        </a>
                      </div>
                      <div v-else class="col-12">
                        <a
                          @click="gotToShop()"
                          href="#"
                          class="ps-btn ps-btn--black"
                          style="margin: 5px 0px"
                          >Continue Shopping</a
                        >
                      </div>
                      <div
                        class="col-12"
                        v-if="auth.isAuthenticated && cart.cartCount > 0"
                      >
                        <router-link
                          :to="{ name: 'checkout' }"
                          class="ps-btn btn-secondary"
                          style="margin: 5px 0px"
                          >Checkout</router-link
                        >
                      </div>
                      <div class="col-12" v-else-if="cart.cartCount > 0">
                        <button
                          @click="onCheckout()"
                          class="ps-btn btn-secondary"
                          style="margin: 5px 0px"
                        >
                          Checkout
                        </button>
                      </div>
                      
                      <div v-if="onCart" class="col-12">
                        <a
                          class="ps-btn ps-btn--black"
                          style="margin: 5px 0px"
                          @click="expressCheckout()"
                          href="#"
                          >Express Checkout
                          <div
                            v-if="loading"
                            class="spinner-border text-dark"
                            role="status"
                          ></div>
                        </a>
                      </div>
                      <div class="w-100 mt-2 d-flex flex-row gap-3">
                        <!--ToDo: Figure out component for the to route-->
                        <router-link
                          :to="{ name: 'home' }"
                          class="nav-links-btn"
                          >Return to Last Aisle</router-link
                        >
                        <!--ToDo: Figure out component for the to route-->
                        <router-link
                          :to="{ name: 'home' }"
                          class="nav-links-btn"
                          >Go to OneStopStores</router-link
                        >

                        <router-link
                          :to="{ name: 'home' }"
                          class="nav-links-btn"
                          >Back To Home</router-link
                        >
                        <router-link
                          :to="{ name: 'shop-now' }"
                          class="nav-links-btn"
                          >Go To ShopAll & ShopNow</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ps-page__right">
            <aside class="widget widget_product widget_features">
              <p><i class="icon-network"></i>Delivery countrywide</p>
              <p>
                <i class="icon-receipt"></i> Supplier give bills for this
                product.
              </p>
              <p><i class="icon-credit-card"></i> Pay online</p>
            </aside>
            <aside class="widget widget_sell-on-site">
              <p>
                <i class="icon-store"></i> Sell on Pahukama ?
                <router-link :to="{ name: 'merchant_register' }"
                  >Register Now !</router-link
                >
              </p>
            </aside>
          </div>
        </div>
      </div>
    </div>
    <div class="ps-newsletter">
      <div class="container">
        <form class="ps-form--newsletter">
          <div class="row"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import global from "@/mixins/global.js";
import BreadCrumb from "@/components/Navs/BreadCrumb";
import axios from "axios";
import { mapState } from "vuex";

export default {
  components: { BreadCrumb },
  data() {
    return {
      product: {},
      loading: true,
      messages: [],
      quantity: 1,
      onCart: false,
    };
  },
  mixins: [global],

  mounted() {
    this.getProduct();
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
    productColors() {
      console.log(this.product.properties);
      // return this.product.properties.filter(prop => prop.color)
    },
    productSizes() {
      console.log(this.product.properties);
      // return this.product.properties.filter(prop => prop.size)
    },
  },
  methods: {
    getProduct() {
      axios
        .get(`products/${this.$route.params.slug}/detail`)
        .then((response) => {
          this.product = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    addQuantity() {
      this.quantity += 1;
    },

    removeQuantity() {
      this.quantity -= 1;
    },

    gotToShop() {
      location.href = "/shop";
    },

    addToCart() {
      // this.loading = true;
      let newProp = {
        qty: this.quantity,
      };
      if (this.product.properties.hasOwnProperty("color")) {
        newProp["color"] = this.product.properties.color;
      }
      if (this.product.properties.hasOwnProperty("size")) {
        newProp["size"] = this.product.properties.size;
      }
      this.$store.dispatch("cart/addToCart", {
        product: this.product,
        quantity: this.quantity,
      });
      this.onCart = true;

      this.$toasted.show("Product Added To Cart");
    },

    onCheckout() {
      this.$store.commit("auth/next", "checkout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="css" scoped>
.main-banner {
  height: 200px;
}

.search-input {
  width: 100%;
}

.btn-warning {
  width: 200px;
}

.nav-links-btn {
  font-size: 12px;
  background-color: #f9a51e;
  font-weight: bold;
  border: none;
  margin: 0px 4px;
  border-radius: 2px;
  text-align: center;
  padding: 2px;
}

.vn-message {
  color: white !important;
}
</style>
